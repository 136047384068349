import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import iconWhatsapp from "../assets/whatsappIcon.svg";
import iconPdf from "../assets/pdfIcon.svg";
import iconFurniture from "../assets/iconFurniture.svg";
import iconKatalog from "../assets/catalog.png";
import iconWebsite from "../assets/websiteIcon.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import backgroundImage from "../assets/backgroundGreen.jpg";

const Main = () => {
  const styleBackground = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
    overflow: "auto",
  };
  return (
    <div className="main" style={styleBackground}>
      <div className="content">
        <h1 className="textMain">SELAMAT DATANG DI</h1>
        <h1 className="textMain">E-KATALOG FURNITURE</h1>
        <Container className="containerCard" style={{ marginTop: "30px" }}>
          <Row>
            <Col md={6}>
              <a href="https://api.whatsapp.com/send/?phone=+6282236444481&text=Mohon+Informasi+Terkait+Furniture&type=phone_number&app_absent=0" className="anchor">
                <Card
                  className="cards"
                  style={{
                    width: "212px",
                    textAlign: "center",
                    borderRadius: "30px",
                    height: "140px",
                  }}
                >
                  <Card.Body>
                    <LazyLoadImage
                      effect="blur"
                      className="cardImageTop"
                      style={{
                        width: "70px",
                        alignItems: "center",
                        opacity: 1,
                        marginBottom: "5px",
                      }}
                      src={iconWhatsapp}
                    />
                  </Card.Body>
                  <Card.Text>
                    <p className="cardText">HUBUNGI KAMI</p>
                  </Card.Text>
                </Card>
              </a>
            </Col>
            <Col md={6}>
              <a
                href="brochure.pdf"
                download="brochure.pdf"
                className="anchor"
              >
                <Card
                  className="cards"
                  style={{
                    width: "212px",
                    textAlign: "center",
                    borderRadius: "30px",
                    height: "140px",
                    alignContent: "flex-end",
                  }}
                >
                  <Card.Body>
                    <LazyLoadImage
                      effect="blur"
                      className="cardImageTop"
                      style={{
                        width: "60px",
                        alignItems: "center",
                        opacity: 1,
                        marginBottom: "5px",
                      }}
                      src={iconPdf}
                    />
                    <Card.Text>
                      <p className="cardText">DOWNLOAD BROSUR</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          </Row>
          <Row className="rowFurniture">
            <Col
              md={{ span: 6, offset: 3 }}
              style={{
                textAlign: "center",
              }}
            >
              <LazyLoadImage
                effect="blur"
                className="furnitureIcon"
                style={{
                  width: "450px",
                  marginBottom: "10px",
                }}
                src={iconFurniture}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <a href="https://e-katalog.lkpp.go.id/productsearchcontroller/listproduk?authenticityToken=683ee338f22b5c981f201cf07b908cfafa652354&cat=&commodityId=84247&q=&jenis_produk=&kabid=278&pid=445944&mid=&tkdn_produk=-99&sni=-99&gt=&lt=" className="anchor">
                <Card
                  className="cards"
                  style={{
                    width: "212px",
                    textAlign: "center",
                    borderRadius: "30px",
                    height: "140px",
                    alignItems: "center",
                  }}
                >
                  <Card.Body>
                    <LazyLoadImage
                      effect="blur"
                      className="cardImageTop"
                      style={{
                        width: "110px",
                        marginBottom: "30px",
                      }}
                      src={iconKatalog}
                    />
                    <Card.Text>
                      <p className="cardText">BUKA LINK E-KATALOG</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col md={6}>
              <a href="https://e-katalog.lkpp.go.id/productsearchcontroller/listproduk?authenticityToken=683ee338f22b5c981f201cf07b908cfafa652354&cat=&commodityId=84247&q=&jenis_produk=&kabid=278&pid=445944&mid=&tkdn_produk=-99&sni=-99&gt=&lt=" className="anchor">
                <Card
                  className="cards"
                  style={{
                    width: "212px",
                    textAlign: "center",
                    borderRadius: "30px",
                    height: "140px",
                  }}
                >
                  <Card.Body>
                    <LazyLoadImage
                      effect="blur"
                      className="cardImageTop"
                      style={{
                        width: "70px",
                        marginBottom: "10px",
                      }}
                      src={iconWebsite}
                    />
                    <Card.Text>
                      <p className="cardText">BUKA WEBSITE KAMI</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          </Row>
        </Container>
        <h1 className="textFooterMain">2023 Copyright www.cvline.id</h1>
      </div>
    </div>
  );
};

export default Main;
